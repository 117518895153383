























import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Tool from '@/shared/modules/tool/models/tool.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { RoleLevel } from '@/shared/modules/role/role.model'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import router from '@/shared/router'
import can from '@/shared/helpers/can.helper'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import ShowAction from '@/shared/classes/components/data-table/default-actions/show-action'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import ToolService, { GenerateType } from '@/shared/modules/tool/tool.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'

@Component({
    components: { Can, DataTable, PageHeader },
    methods: { __ }
  })
  export default class ToolsIndex extends Vue {
    types = GenerateType
    permissions = permissions
    table: DataTableBase|null = null
    loading: GenerateType|null = null
    loadingPrice: boolean = false
    price: string|null = null

    created() {
      this.table = new DataTableBase()
        .setModel(Tool)
        .setEndpoint(`company/${ this.company.slug }/tools`)
        .setRowFilterInHeading(true)
        .setHeaders([
          new DataTableHeader()
            .setType(DataTableHeaderTypes.textLink)
            .setKey('internal_no')
            .setText(__('company.views.tools.index.table.columns.internal_no'))
            .setMeta({
              getRawLocation: (tool: Tool) => ({
                name: CompanyRoutes.toolsShow,
                params: {
                  company: this.company.slug,
                  uuid: tool.uuid
                }
              })
            }),
          new DataTableHeader()
            .setKey('name')
            .setEntryKey('name')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.name')),
          new DataTableHeader()
            .setKey('location.name')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.location')),
          new DataTableHeader()
            .setKey('category.name')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.category')),
          new DataTableHeader()
            .setKey('rental_external_price')
            .setEntryKey('rental_external_price')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.rental_external_price')),
          new DataTableHeader()
            .setKey('rental_internal_price')
            .setEntryKey('rental_internal_price')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.rental_internal_price')),
          new DataTableHeader()
            .setKey('retrieved_by.full_name')
            .setEntryKey('retrievedBy.fullName')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.retrieved-by')),
          new DataTableHeader()
            .setKey('comment')
            .setEntryKey('comment')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.comment')),
          new DataTableHeader()
            .setType(DataTableHeaderTypes.textLink)
            .setKey('link')
            .setText(__('company.views.tools.index.table.columns.link'))
            .setSortable(false)
            .setMeta({
              getRawLocation: (tool: Tool) => ({
                path: tool.link
              })
            }),
          new DataTableHeader()
            .setKey('discarded_at')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.discarded_date')),
          new DataTableHeader()
            .setKey('discarded_by.full_name')
            .setEntryKey('discardedBy.fullName')
            .setSortable(false)
            .setText(__('company.views.tools.index.table.columns.discarded_by'))
        ])
        .setFilters([
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('location_uuid')
                .setTitle(__('company.views.tools.index.table.filters.location'))
                .loadItems({ endpoint: `/company/${ this.company.slug }/tool-locations`, value: 'uuid', title: 'name', perPage: 20 })
                .setSize(FieldSizes.threeTwelfth)
            ),
          new DataTableFilter()
            .setOperator(FilterOperators.equals)
            .setKeyIsPrimary()
            .setField(
              new SearchableField()
                .setKey('category_uuid')
                .setTitle(__('company.views.tools.index.table.filters.category'))
                .loadItems({ endpoint: `/company/${ this.company.slug }/tool-categories`, value: 'uuid', title: 'name', perPage: 20 })
                .setSize(FieldSizes.threeTwelfth)
            ),
          new DataTableFilter()
            .setOperator(FilterOperators.equalsYear)
            .setKeyIsPrimary()
            .setField(
              new SelectField()
                .setKey('discarded_at')
                .setTitle(__('company.views.tools.discarded.table.filters.discarded_year'))
                .setItems((() => {
                  const year = new Date().getFullYear()
                  return Array.from({length: year - 2000}, (value, index) => {
                    const s = new SelectItem()
                    const val = year - index
                    s.setValue(val)
                    s.setTitle('' + val)
                    return s
                  })
                })())
                .setSize(FieldSizes.threeTwelfth)
            ),
        ])
        .setOnChange(this.loadPrice)
        .setDefaultFilters([
          {
            operator: FilterOperators.nulled,
            key: 'discarded_at',
            value: false
          }
        ])
        .setActions([
          new ShowAction()
            .setPermissions([
              permissions.company.tools.show
            ])
            .setAction((tool: Tool) => this.$router.push({
              name: CompanyRoutes.toolsShow,
              params: {
                company: this.company.slug,
                uuid: tool.uuid
              }
            })),
        ])

      this.loadPrice()
    }

    loadPrice(): void {
      this.loadingPrice = true

      const category = (this.$router.currentRoute.query['filter-equals-category_uuid'] ?? '') as string
      const location = (this.$router.currentRoute.query['filter-equals-location_uuid'] ?? '') as string
      const q = (this.$router.currentRoute.query['q'] ?? '') as string
      const year = (this.$router.currentRoute.query['filter-equalsYear-discarded_at'] ?? '') as string

      ToolService.discardedPrice(this.company, true, year, location, category, q)
        .then((response: any) => {
          this.price = response.price
        })
        .finally(() => this.loadingPrice = false)
    }

    generate(type: GenerateType): void {
      this.loading = type

      const query = this.$router.currentRoute.query
      const location = (query['filter-equals-location_uuid'] ?? '') as string
      const category = (query['filter-equals-category_uuid'] ?? '') as string
      const q = (query['q'] ?? '') as string
      const isDiscarded = true

      ToolService.export(this.company, type, location, category, q, true)
        .then((response: any) => {
          downloadFile(response, `tools.${ type }`)
        })
        .finally(() => this.loading = null)
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }

    get user(): User {
      return this.$store.getters[AuthGetters.getUser]
    }
  }
